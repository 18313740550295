import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Formulario from './Formulario';
import Registro from './Registro';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Router>
    <Routes>
      <Route path="/" element={<Navigate to="/formulario" />} />
      <Route path="/formulario" element={<Formulario />} />
      <Route path="/registro" element={<Registro />} />
    </Routes>
  </Router>
);
