import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Form, Row, Col, Container } from 'react-bootstrap';

const Formulario = () => {
  const [localOptions, setLocalOptions] = useState([]);
  const [razonSocialOptions, setRazonSocialOptions] = useState([]);
  const [selectedLocalOption, setSelectedLocalOption] = useState(null);
  const [selectedRazonSocialOption, setSelectedRazonSocialOption] = useState(null);
  const [modulo, setModulo] = useState('');
  const [comentario, setComentario] = useState('');
  const [razonSocial, setRazonSocial] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://portocomercio.gob.ec/helpers/intranet/arrendatarioplaza.php')
      .then(response => {
        const localOptions = response.data.map(item => ({
          value: item.ID,
          label: item.Local,
          cedula: item.Cedula,
          propietario: item.Propietario
        }));

        const uniqueRazonSocial = Array.from(new Set(response.data.map(item => item.RazonSocial.trim())))
          .map(razonSocial => {
            const item = response.data.find(i => i.RazonSocial.trim() === razonSocial);
            return { value: item.ID, label: item.RazonSocial.trim() };
          })
          .sort((a, b) => a.label.localeCompare(b.label)); // Ordena alfabéticamente

        setLocalOptions(localOptions);
        setRazonSocialOptions(uniqueRazonSocial);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);

    if (selectedLocalOption && razonSocial && modulo.match(/^\d{1,3}$/)) {
      const data = {
        Local: selectedLocalOption.label,
        Categoria: selectedRazonSocialOption.label,
        Modulo: modulo,
        RazonSocial: razonSocial,
        Comentario: comentario,
        Cedula: selectedLocalOption.cedula,
        Propietario: selectedLocalOption.propietario
      };

      console.log('Form data to send:', data);

      axios.post('https://portocomercio.gob.ec/helpers/intranet/EncuestaPlazaCentral/encuestaplazacentral.php', data)
        .then(response => {
          console.log('Response:', response);
          setModalContent('Registro exitoso');
          setModalVisible(true);
          setSelectedLocalOption(null);
          setRazonSocial('');
          setModulo('');
          setRazonSocial('');
          setComentario('');
          setIsSubmitting(false);
        })
        .catch(error => {
          console.error('Error:', error);
          setModalContent('No se pudo enviar el formulario');
          setModalVisible(true);
          setIsSubmitting(false);
        });
    } else {
      console.log('Please fill in all required fields');
      setIsSubmitting(false);
    }
  };

  const handleModuloChange = (event) => {
    const value = event.target.value;
    if (value.match(/^\d{0,3}$/)) {
      setModulo(value);
    }
  };

  const handleComentarioChange = (event) => {
    const value = event.target.value;
    if (value.length <= 300) {
      setComentario(value);
    }
  };

  const handleRazonSocialChange = (event) => {
    const value = event.target.value;
    setRazonSocial(value);
  };

  const handleLinkClick = () => {
    navigate('/registro');
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <Container className="mt-5">
      <h1 className="text-center mb-4">Registro de Módulos PLAZA CENTRAL</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Row} controlId="local-select">
          <Form.Label column sm={2}><strong>Seleccione el local:</strong></Form.Label>
          <Col sm={10}>
            <Select
              options={localOptions}
              value={selectedLocalOption}
              onChange={option => setSelectedLocalOption(option)}
              required
            />
          </Col>
        </Form.Group>
        
        <Form.Group as={Row} controlId="razon-social-select" className="mt-3">
          <Form.Label column sm={2}><strong>Seleccione la categoria:</strong></Form.Label>
          <Col sm={10}>
            <Select
              options={razonSocialOptions}
              value={selectedRazonSocialOption}
              onChange={option => setSelectedRazonSocialOption(option)}
              required
            />
          </Col>
        </Form.Group>
        
        <Form.Group as={Row} controlId="modulo-input" className="mt-3">
          <Form.Label column sm={2}><strong>Módulo:</strong></Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              value={modulo}
              onChange={handleModuloChange}
              maxLength="3"
              required
            />
          </Col>
        </Form.Group>
        
        <Form.Group as={Row} controlId="razon-social-input" className="mt-3">
          <Form.Label column sm={2}><strong>Razon Social:</strong></Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              value={razonSocial}
              onChange={handleRazonSocialChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="comentario-input" className="mt-3">
          <Form.Label column sm={2}><strong>Comentario:</strong></Form.Label>
          <Col sm={10}>
            <Form.Control
              as="textarea"
              rows={3}
              value={comentario}
              onChange={handleComentarioChange}
              maxLength="300"
            />
          </Col>
        </Form.Group>
        
        <div className="text-center mt-4">
          <Button type="submit" disabled={isSubmitting}>Enviar</Button>
        </div>
        
        <p className="text-center mt-3"><strong>Si el comerciante no se encuentra registrado de click </strong>
          <button 
            type="button" 
            onClick={handleLinkClick} 
            style={{ 
              background: 'none', 
              color: 'blue', 
              textDecoration: 'underline', 
              border: 'none', 
              padding: 0, 
              cursor: 'pointer' 
            }}>
            AQUÍ
          </button>
        </p>
      </Form>

      <Modal show={modalVisible} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Resultado</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Formulario;
