import React, { useState, useEffect } from 'react';
import { Form, Button, Col, Row, Container, Spinner, Alert } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';

const Registro = () => {
  const [form, setForm] = useState({
    cedula: '',
    nombre1: '',
    nombre2: '',
    apellido1: '',
    apellido2: '',
    categoria: '',
    modulo: '',
    razonSocial: ''
  });

  const [categories, setCategories] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://portocomercio.gob.ec/helpers/intranet/arrendatarioplaza.php');

        const cleanedCategories = response.data.map(category => ({
          ID: category.ID,
          RazonSocial: category.RazonSocial.trim()
        }));

        const uniqueCategories = Array.from(
          new Set(cleanedCategories.map(category => category.RazonSocial))
        ).map(razonSocial => {
          return cleanedCategories.find(category => category.RazonSocial === razonSocial);
        });

        const sortedCategories = uniqueCategories.sort((a, b) => a.RazonSocial.localeCompare(b.RazonSocial));

        const options = sortedCategories.map(category => ({
          value: category.RazonSocial,
          label: category.RazonSocial
        }));

        setCategories(options);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'modulo') {
      const re = /^[0-9\b]+$/;
      if (value === '' || (re.test(value) && value.length <= 3)) {
        setForm({ ...form, [name]: value });
      }
      return;
    }

    setForm({ ...form, [name]: value });
  };

  const handleSelectChange = (selectedOption) => {
    setForm({ ...form, categoria: selectedOption ? selectedOption.value : '' });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.cedula) newErrors.cedula = 'Cédula es requerida';
    if (!form.nombre1) newErrors.nombre1 = 'Primer nombre es requerido';
    if (!form.nombre2) newErrors.nombre2 = 'Segundo nombre es requerido';
    if (!form.apellido1) newErrors.apellido1 = 'Primer apellido es requerido';
    if (!form.apellido2) newErrors.apellido2 = 'Segundo apellido es requerido';
    if (!form.categoria) newErrors.categoria = 'Categoría es requerida';
    if (!form.modulo) newErrors.modulo = 'Módulo es requerido';
    if (!form.razonSocial) newErrors.razonSocial = 'Razón Social es requerida';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsSubmitting(false);
      return;
    }
    setErrors({});
    const postData = {
      CedulaRuc: form.cedula,
      Nombre1: form.nombre1,
      Nombre2: form.nombre2,
      Apellido1: form.apellido1,
      Apellido2: form.apellido2,
      Modulo: form.modulo,
      Categoria: form.categoria,
      RazonSocial: form.razonSocial
    };

    try {
      const response = await axios.post('https://portocomercio.gob.ec/helpers/intranet/EncuestaPlazaCentral/encuestaplazacentraladicional.php', postData);
      console.log('Response:', response.data);
      setForm({
        cedula: '',
        nombre1: '',
        nombre2: '',
        apellido1: '',
        apellido2: '',
        categoria: '',
        modulo: '',
        razonSocial: ''
      });
      setShowSuccess(true);
      setShowError(false);
    } catch (error) {
      console.error('Error submitting form:', error);
      setShowError(true);
      setShowSuccess(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container className="mt-5 d-flex justify-content-center">
      <div style={{ maxWidth: '600px', width: '100%' }}>
        <h1 className="text-center">Registro</h1>
        {showSuccess && <Alert variant="success">Registro exitoso!</Alert>}
        {showError && <Alert variant="danger">Hubo un error al enviar el formulario.</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row} controlId="formCedula">
            <Form.Label column sm={2}><b>Cédula</b></Form.Label>
            <Col sm={10}>
              <Form.Control 
                type="text" 
                name="cedula" 
                value={form.cedula} 
                onChange={handleChange} 
                isInvalid={!!errors.cedula}
                required 
                disabled={isSubmitting}
              />
              <Form.Control.Feedback type="invalid">
                {errors.cedula}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formNombre1">
            <Form.Label column sm={2}><b>Nombre 1</b></Form.Label>
            <Col sm={10}>
              <Form.Control 
                type="text" 
                name="nombre1" 
                value={form.nombre1} 
                onChange={handleChange} 
                isInvalid={!!errors.nombre1}
                required 
                disabled={isSubmitting}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nombre1}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formNombre2">
            <Form.Label column sm={2}><b>Nombre 2</b></Form.Label>
            <Col sm={10}>
              <Form.Control 
                type="text" 
                name="nombre2" 
                value={form.nombre2} 
                onChange={handleChange} 
                isInvalid={!!errors.nombre2}
                required
                disabled={isSubmitting}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nombre2}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formApellido1">
            <Form.Label column sm={2}><b>Apellido 1</b></Form.Label>
            <Col sm={10}>
              <Form.Control 
                type="text" 
                name="apellido1" 
                value={form.apellido1} 
                onChange={handleChange} 
                isInvalid={!!errors.apellido1}
                required 
                disabled={isSubmitting}
              />
              <Form.Control.Feedback type="invalid">
                {errors.apellido1}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formApellido2">
            <Form.Label column sm={2}><b>Apellido 2</b></Form.Label>
            <Col sm={10}>
              <Form.Control 
                type="text" 
                name="apellido2" 
                value={form.apellido2} 
                onChange={handleChange} 
                isInvalid={!!errors.apellido2}
                required
                disabled={isSubmitting}
              />
              <Form.Control.Feedback type="invalid">
                {errors.apellido2}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formCategoria">
            <Form.Label column sm={2}><b>Categoría</b></Form.Label>
            <Col sm={10}>
              <Select
                name="categoria"
                options={categories}
                onChange={handleSelectChange}
                value={categories.find(option => option.value === form.categoria)}
                isClearable
                isDisabled={isSubmitting}
                required
                classNamePrefix="react-select"
              />
              {errors.categoria && <div className="invalid-feedback d-block">{errors.categoria}</div>}
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formModulo">
            <Form.Label column sm={2}><b>Módulo</b></Form.Label>
            <Col sm={10}>
              <Form.Control 
                type="text" 
                name="modulo" 
                value={form.modulo} 
                onChange={handleChange} 
                maxLength="3" 
                isInvalid={!!errors.modulo}
                required 
                disabled={isSubmitting}
              />
              <Form.Control.Feedback type="invalid">
                {errors.modulo}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formRazonSocial">
            <Form.Label column sm={2}><b>Razón Social</b></Form.Label>
            <Col sm={10}>
              <Form.Control 
                type="text" 
                name="razonSocial" 
                value={form.razonSocial} 
                onChange={handleChange} 
                isInvalid={!!errors.razonSocial}
                required 
                disabled={isSubmitting}
              />
              <Form.Control.Feedback type="invalid">
                {errors.razonSocial}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <div className="text-center mt-3">
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              {isSubmitting ? <Spinner animation="border" size="sm" /> : 'Registrar'}
            </Button>
          </div>
        </Form>
      </div>
    </Container>
  );
};

export default Registro;
